import { sxAjax as req } from "@/commons/ajax";
// import axios from "axios";
// import { getLoginUser } from "@/commons";

// const { token } = getLoginUser();

// axios.defaults.baseURL = "http://host.52guchen.top";
// axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
import * as qs from "qs";

declare var abp: any;
const domain = abp.setting.get("App.Api.IntegrateSvc.Host");

/**
 *
 * @param {*} formId
 * @returns
 */
export const getFormById = async (formId) => {
    return await req.get("api/services/app/AppObject/GetForm?formId=" + formId);
};

export const GetCategoryList = async (isPaper, isConfig) => {
    return await req.get(
        `api/services/app/Category/GetFileCategoryList?type=${isPaper}&isConfig=${isConfig}`
    );
};
// 档案类别
export const GetSelectedDataBaseByField = async (isPaper, isConfig) => {
    return await req.get(
        `api/services/app/Category/GetSelectedDataBaseByField?fieldName=FilingType`
    );
};
// 档案收集
export const GetCollectCategoryList = async (isPaper, isConfig, isCollect) => {
    return await req.get(
        `api/services/app/Category/GetFileCategoryList?type=${isPaper}&isConfig=${isConfig}&isCollect=${isCollect}`
    );
};

export const getFiltersOrField = async (formId, step) => {
    return await req.get(
        "api/services/app/General/GetFields?formId=" + formId + "&step=" + step
    );
};

export const getDataList = async (sData) => {
    return await req.post(`/api/services/app/EaaMetadata/GetMetadata2DictionaryList`, sData);
    //return await req.post("/api/services/app/General/GetTableListData", sData);
};
export const FileLastDetail = async (params) => {
    return await req.post(`/api/services/app/Filling/FileLastDetail`, params);
}
export const getDataListDictionary = async (params) => {
    return await req.get(`/api/services/app/EaaMetadata/GetMetadata2Dictionary`, params);
}
export const getMetadataByRelationField = async (params) => {
    return await req.get(`/api/services/app/EaaMetadata/GetMetadataByRelationField`, params);
}
export const getDetailList = async (params) => {
    return await req.get(`/api/services/app/Handover/GetMetadataList`, params);
}

export const saveData = async (sData) => {
    return await req.post(
        "/api/services/app/DocumentForm/SaveFormDataBatch",
        sData
    );
};
/**
 * 获取打印模板
 * @param {*} cid
 * @returns
 */
export const getPrintTemplate = async (cid) => {
    return await req.get(
        "/api/services/app/PrintTemplate/GetPrintTemplateConfig?categoryId=" +
        cid
    );
};
export const getEaaPrintTemplate = async (cid) => {
    return await req.get(
        "/api/services/app/EaaPrintTemplate/GetPrintTemplateConfig?categoryId=" +
        cid
    );
};
export const getFileTypesForm = async () => {
    return await req.get(`/api/services/app/ArchivesService/GetFileTypesForm`);
};

export const getFileCategoryList = async (isPaperFile, isConfig) => {
    return await req.get(
        `/api/services/app/Category/GetFileCategoryList?isPaperFile=${isPaperFile}&isConfig=${isConfig}`
    );
};
export const getPrintTempCategoryList = async () => {
    return await req.get(
        `/api/services/app/Category/GetPrintTempCategoryList`
    );
};
export const getBillInfo = async (params) => {
    return await req.get(
        `/api/services/app/EaaMetadata/GetYSBillInfo`,
        params
    );
};
// 获取层级
export const getBindingList = async (params) => {
    return await req.get(
        `/api/services/app/EaaPrintTemplate/GetBindingRules?`,
        params
    );
};

export const GetPrintInVolumeDirectory = async (params) => {
    return await req.post(
        `/api/services/app/EaaPrintTemplate/GetPrintInVolumeDirectory`,
        params
    );
};
export const executeConnect = async (params) => {
    return await req.post(
        `/api/services/app/EaaMetadata/ExecuteConnect`,
        params
    );
};

export const getFields = async (formId, step) => {
    return await req.get(
        `/api/services/app/General/GetFields?formId=${formId}&step=${step}`
    );
};

export const GetCategoryInfo = async (docName) => {
    return await req.get(
        "api/services/app/Category/GetFileCategory?name=" + docName
    );
};
/**获取表单字段 */
export const GetTableAllFields = async (categoryId, granularity, rule) => {
    return await req.get(
        `/api/services/app/EaaPrintTemplate/GetTableAllFields?categoryId=${categoryId}&granularity=${granularity}&rule=${rule}`
    );
};
// export const getDocTree = async (params) => {
//     return await req.post(
//         `/api/services/app/ArchivesTree/GetArchivesTree`,
//         params
//     );
// };

export const getDocTree = async (params) => {
    return await req.post(
        `/api/services/app/ArchivesTree/GetQueryTree`,
        params
    );
};

export const getPreviewTree = async (params) => {
    return await req.post(
        `/api/services/app/MetaDataArchivesTree/Metadata_PreviewTree`,
        params
    );
};

export const getNewTree = async (params) => {
    return await req.post(
        `/api/services/app/MetaDataArchivesTree/Metadata_GetTree`,
        params
    );
};

export const getTreeChildrenGet = async (mastId, docTypeId, archivesKey) => {
    return await req.get(
        `/api/services/app/ArchivesTree/GetTreeImages?mstId=${mastId}&docTypeId=${docTypeId}&archivesKey=${archivesKey}`
    );
};
export const getTreeChildren = async (params) => {
    return await req.post(
        `/api/services/app/MetaDataArchivesTree/Metadata_GetTreeNext`,
        params
    );
};
export const getDocumentUrl = async (docId) => {
    return await req.get(
        `/api/services/app/ArchivesTree/GetDocumentURL?docId=${docId}`
    );
};

export const GetDocumentURLPublic = async (docId) => {
    return await req.get(
        `/api/services/app/ArchivesTree/GetDocumentURLPublic?docId=${docId}`
    );
};

export const getDownloadUrl = async (docId) => {
    return await req.get(
        `/api/services/app/ArchivesTree/GetDownloadUrl?documentId=${docId}`
    );
};
export const getSourceDownloadUrl = async (docId) => {
    return await req.get(
        `/api/services/app/ArchivesTree/GetSourceDownloadUrl?documentId=${docId}`
    );
};
export const QueryDocumentId = async (params) => {
    return await req.post(
        `/documentservice/api/Image/QueryDocumentId`,
        params
    );
};

// export const ImagePreView = async (params) => {
//     return await req.post(
//         `/api/services/app/RelationFormAndAnnex/ImagePreView`,
//         params
//     );
// };


/**
 * 获取单据类型列表
 * @returns
 */
export const getBillConfig = async () => {
    return await req.get("/api/services/app/ApplicationForm/GetMetaDataInfo");
};
/**
 * 预览查看信息结构化信息获取 mstId
 * @returns
 */
export const getDataId = async (documentTypeId, archivesKey) => {
    return await req.get(`/api/services/app/RelationFormAndAnnex/GetFormData?documentTypeId=${documentTypeId}&archivesKey=${archivesKey}`);
};

export const getFormDataList = async () => {
    var res1 = await req.get(`${abp.setting.get("App.Api.MetaData.Api")}documentType/get/list?fieldName=&fieldValue=&formType=business&page=0&limit=1000`);
    var res2 = await req.get(`${abp.setting.get("App.Api.MetaData.Api")}documentType/get/list?fieldName=&fieldValue=&formType=basic&page=0&limit=1000`);
    if (res2?.content) {
        const content = [...(res1?.content || [])];
        for (let index = 0; index < res2?.content?.length; index++) {
            const item = res2.content[index];
            content.push(item);
        }
        res1.content = content;
    }
    return res1;
};

/**
 * 获取日志
 * @param {*} mstId
 * @param {*} type
 * @returns
 */
export const getLoggers = async (mstId, type) => {
    return await req.get(
        "/api/services/app/Logger/GetLoggers?mstId=" + mstId + "&type=" + type
    );
};
/**
 * 写日志
 * @param {*} sData
 * @returns
 */
export const writeLog = async (sData) => {
    return await req.post("/api/services/app/Logger/Log", sData);
};

export const getFormDataInfo = async (documentTypeId) => {
    return await req.get(
        `/api/services/app/DocumentForm/GetFormInfo?documentTypeId=${documentTypeId}`
    );
};
export const getSupportModifyFields = async (documentTypeId) => {
    return await req.get(
        `/api/services/app/BatchOperation/GetSupportModifyFields?documentTypeId=${documentTypeId}`
    );
};
export const getModifyConfig = async (categoryId) => {
    return await req.get(
        `/api/services/app/ReportConfig/GetModifyFieldConfig?categoryId=${categoryId}`
    );
};
export const getSelectList = async (identity) => {
    return await req.get(
        `/api/services/app/BatchOperation/GetSelectList?identity=${identity}`
    );
};
export const GetTypeTree = async () => {
    return await req.get(
        `/documentservice/api/Business/Management/GetType/Tree`
    );
};
/**
 * 获取归档主体排序字段
 * @param {} params
 * @returns
 */
export const getSortField = async (params) => {
    return await req.get(
        `/api/services/app/ReportConfig/GetReportConfigByCategoryId?objectId=${params}`
    );
}

/**
 * 获取归档主体规则配置信息
 * @param {*} objId
 * @returns
 */
export const getCategoryConfig = async (objId) => {
    return await req.get(
        `/api/services/app/Category/GetCategoryConfig?objectId=${objId}`
    );
}
/**
 * 保存归档主体规则配置信息
 * @param {*} params
 * @returns
 */
export const saveCategoryConfig = async (params) => {
    return await req.post('/api/services/app/Category/SaveCategoryConfig', params);
}
/**
 * 获取归档主体表单配置
 * @param {*} objId
 * @returns
 */
export const getCategoryFormConfig = async (objId) => {
    return await req.get(
        `/api/services/app/Category/GetCategoryFormConfig?categoryId=${objId}`
    );
}
/**
 * 获取归档频率设置
 * @param {*} objId
 * @returns
 */
export const getArchivingFrequency = async (objId) => {
    return await req.get(
        `/api/services/app/EaaFilingFrequencyConfig/GetFilingFrequencySetting?categoryid=${objId}`
    );
}
/**
 * 保存归档主体表单配置
 * @param {*} params
 * @returns
 */
export const saveCategoryFormConfig = async (params) => {
    return await req.post('/api/services/app/Category/SaveCategoryFormConfig', params);
}
// 基础信息保存编辑
export const saveFileCategory = async (params) => {
    return await req.post(
        "/api/services/app/Category/SaveFileCategory",
        params
    );
};
/**
 * 保存归档频率设置
 * @param {*} params
 * @returns
 */
export const saveArchiveFrequency = async (params) => {
    return await req.post('/api/services/app/EaaFilingFrequencyConfig/SettingFilingFrequency', params);
}
/**
 * 获取组织架构
 * @param {*} id
 * @param {*} isGroup
 * @param {*} isAll
 * @returns
 */
export const getOrganizations = async (id, isGroup, isAll) => {
    return await req.get(
        `/api/services/app/OrganizationUnit/GetTreeData?IsGroup=${isGroup}&IsAll=${isAll}&id=${id}`
    );
}
/**
 * 获取组织架构
 * @returns
 */
export const GetOrganizationUnits = async () => {
    return await req.get('/api/services/app/OrganizationUnit/GetOrganizationUnits');
}
/**
 * 获取组织架构
 * @returns
 */
 export const GetAuthorizeCompanyList = async () => {
    return await req.get('/api/services/app/Warehouse/GetAuthorizeCompanyList?NotElectronic=true');
}
/**
 * 获取角色
 * @returns
 */
export const GetFilterRoles = async () => {
    return await req.get('/api/services/app/Role/GetFilterRoles');
}
/**
 * 获取用户信息
 * @param {默认false} OnlyLockedUsers
 * @returns
 */
export const GetUsers = async (OnlyLockedUsers) => {
    return await req.get(`/api/services/app/User/GetUsers?OnlyLockedUsers=${OnlyLockedUsers}&MaxResultCount=100&SkipCount=0`);
}
/**
 * 获取表单按钮控制
 * @param {*} categoryId
 * @param {*} step
 * @returns
 */
export const getFormButton = async (categoryId, step) => {
    return await req.get(`/api/services/app/FormButton/GetFormButton?categoryId=${categoryId}&step=${step}`);
}
/**
 * 删除归档主体表单配置
 * @param {*} params
 * @returns
 */
export const deleteCategoryFormConfig = async (params) => {
    return await req.post(`/api/services/app/Category/RemoveCategoryFormConfig?id=${params}`)
}
/**
 * 获取档案主体权限
 * @param {*} cid
 * @returns
 */
export const getCategoryPermission = async (cid) => {
    return await req.get(`/api/services/app/EaaPermissions/GetPermissions?categoryId=${cid}`);
}
/**
 * 发布档案主体权限
 * @param {*} params
 * @returns
 */
export const publishCategoryPermission = async (params) => {
    return await req.post('/api/services/app/EaaPermissions/SavePermissions', params);
}
/**
 * 添加档案主体
 * @param {*} params
 * @returns
 */
export const addCategory = async (params) => {
    return await req.post('/api/services/app/Category/IncreaseCategory', params);
}
/**
 * 修改档案主体
 * @param {*} params
 * @returns
 */
export const updateCategory = async (params) => {
    return await req.post('/api/services/app/Category/ReviseCategory', params)
}
/**
 * 删除归档主体
 * @param {*} cid
 * @returns
 */
export const deleteCategory = async (cid) => {
    return await req.post(`/api/services/app/Category/DeletGroup?objectId=${cid}`);
}

/**
 * 档案主体排序
 * @param {*} params
 * @returns
 */
export const moveCategory = async (params) => {
    return await req.post('/api/services/app/Category/SortCategory', params);
}
/**
 * 表单按钮
 * @param {*} cid
 * @param {*} step
 * @returns
 */
export const getFormButtons = async (cid, step) => {
    return await req.get(`/api/services/app/Category/GetFormButtons?objectId=${cid}&step=${step}`)
}
/**
 * 获取关联表单
 * @param {*} objId
 * @returns
 */
export const getScannerCategorys = async (objId) => {
    return await req.get(`/api/services/app/Category/GetScannerCategoryList?objectId=${objId}`);
}
/**
 * 获取元数据列表
 * @param {*} params
 * @returns
 */
export const getMetadataList = async (params) => {
    return await req.post('/api/services/app/EaaMetadata/GetMetadataPageList', params);
}
/**
 * 根据名称获取报表配置
 * @param {*} name
 * @returns
 */
export const GetConfigList = async (categoryId, step) => {
    return await req.get(`/api/services/app/ReportConfig/GetConfigList?categoryId=${categoryId}&step=${step}`);
}
/**
 * 列表条数查询
 * @param {*} params
 * @returns
 */
export const GetPageTotalCount = async (params) => {
    return await req.post('/api/services/app/EaaCommon/GetPageTotalCount', params);
}
/**
 * 列表条数查询
 * @param {*} params
 * @returns
 */
export const GetShelftPageTotalCount = async (params) => {
    return await req.post('/api/services/app/EaaCommon/GetShelftPageTotalCount', params);
}
/**
 * 册列表查询
 * @param {*} params
 * @returns
 */
export const getVolumeList = async (params) => {
    return await req.post('/api/services/app/EaaVolume/GetVolumePageList', params);
}
/**
 * 上架表查询
 * @param {*} params
 * @returns
 */
export const getShelftList = async (params) => {
    return await req.post('/api/services/app/EaaArchivesOnShelves/GetFileListByCustomNew', params);
}

/**
 * 获取档案归档频率
 * @param {*} params
 * @returns
 */
export const getArchivingFrequencyList = async (params) => {
    return await req.post('/api/services/app/EaaFilingFrequencyConfig/GetArchiveStatistics', params);
};
/**
 * 更新某条数据的归档频率
 * @param {*} params
 * @returns
 */
export const updateArchivingFrequencyList = async (params) => {
    return await req.post('/api/services/app/EaaArchiveFrequencyStatistics/GetFilingFrequencyDetail', params);
};
/**
 * 获取归档完结
 * @param {*} params
 * @returns
 */
export const admingetArchiveList = async (params) => {
    return await req.post('/api/services/app/EaaFilingFrequencyConfig/GetTotalArchiveStatistics', params);
}
/**
 * 归档确认完结
 * @param {*} params
 * @returns
 */
export const adminAffirmArchive = async (params) => {
    return await req.post('/api/services/app/EaaFilingFrequencyConfig/ConfirmFilingEnd', params);
}
/**
 * 归档提醒
 * @param {*} params
 * @returns
 */
export const archiveRemind = async (params) => {
    return await req.post('/api/services/app/EaaArchiveFrequencyStatistics/SendMessageManual', params);
}

/**
 * 获取报销单列表
 * @param {*} name
 * @returns
 */
export const GetBillData = async (index, size) => {
    return await req.get(`${abp.setting.get("App.Api.IntegrateSvc.Host")}api/ReimbursementThirdparty/Get_BillData?pageIndex=${index}&pageSize=${size}`);
}
/**
 * 推送智能比对
 * @param {*} params
 * @returns
 */
export const SendDataTo = async (params) => {
    return await req.post(`${abp.setting.get("App.Api.IntegrateSvc.Host")}api/ReimbursementThirdparty/SendDataTo_MC01`, params);
}
/**
 * 生成凭证信息
 * @param {*} params
 * @returns
 */
export const GenerateVoucherInfo = async (params) => {
    return await req.post(`${abp.setting.get("App.Api.IntegrateSvc.Host")}api/Voucher/GenerateVoucherInfo`, params);
}

/**
 * 出库确认
 * @param {*} params
 * @returns
 */
export const DealApplicationStatus = async (params) => {
    return await req.post('/api/services/app/EaaApplicationProcess/DealApplicationStatus', params);
}
/**
 * 催还
 * @param {*} params
 * @returns
 */
export const SendNotification = async (params) => {
    return await req.post('/api/services/app/EaaApplicationProcess/SendNotification', params);
}
/* 查询销毁表单信息 */
export const GetForm = async (documentTypeId) => {
    return await req.get(
        `/api/services/app/DocumentForm/GetForm?formId=${documentTypeId}`
    );
};
export const GetAnnexList = async (params) => {
    return await req.get(
        `api/services/app/EaaApplicationProcess/GetAnnexList?taskMstId=${params}`
    );
};

/*法大大合同预览接口*/
export const GetFDDPreview = async (params) => {
    return await req.post(`${abp.setting.get("App.Api.FeatureAccountingArchives.Host")}api/FeatureAccountingArchives/FDDPreview`, params);
};

/*通用日志列表 */
export const GetCommonLoggerList = async (params) => {
    return await req.post(`${abp.setting.get("App.Api.FeatureAccountingArchives.Host")}api/FeatureAccountingArchives/GetCommonLoggerList`, params);
};
/*档案信息接收日志列表 */
export const GetSendArchivesInfoLoggerList = async (params) => {
    return await req.post(`${abp.setting.get("App.Api.FeatureAccountingArchives.Host")}api/FeatureAccountingArchives/GetSendArchivesInfoLoggerList`, params);
};
/*文件上传日志列表 */
export const GetUploadArchivesFileLoggerList = async (params) => {
    return await req.post(`${abp.setting.get("App.Api.FeatureAccountingArchives.Host")}api/FeatureAccountingArchives/GetUploadArchivesFileLoggerList`, params);
};
/**
 * 判断目录查询列表字段是否初始化
 * @param {*} params
 * @returns
 */
export const hasQueryFields = async (params) => {
    return await req.post('/api/services/app/DocumentQuery/InitailDirectoryQueryField', params);
};
/**
 * 目录查询使用
 * @param {*} params
 * @returns
 */
export const getCategoryNewList = async (params) => {
    return await req.get('api/services/app/Category/GetNewCategoryList');
}
export const getCategoryNewConfig = async (params) => {
    return await req.get(
        "api/services/app/Category/GetNewCategoryConfig",
        params
    );
};

export const getCompanyNewList = async (params) => {
    return await req.get('api/services/app/Warehouse/GetAuthorizeCompanyList');
}
/*获取已配置归档主体表单列表 */
export const GetCategoryForm = async (params) => {
    return await req.get('api/services/app/Integrity/GetCategoryForm');
}
// 选择表单字段
export const GetFields = async (documentTypeId) => {
    return await req.get('/api/services/app/DocumentForm/GetFields?documentTypeId=' + documentTypeId);
}
export const GetFormDocFields = async (formId) => {
    return await req.get(`api/services/app/Integrity/GetFormDocFields?formId=${formId}`);
}
export const AddRule = async (params) => {
    return await req.post('api/services/app/RuleIntegrity/CreateRule', params);
};

export const EditRule = async (params) => {
    return await req.post('api/services/app/RuleIntegrity/UpdateRule', params);
};
// export const EditRule = async (params) => {
//     return await req.post('api/services/app/Integrity/EditRule', params);
// };
export const DelRule = async (params) => {
    return await req.post('api/services/app/Integrity/DelRule', params);
};
export const VerificationResult = async (formId, mustId) => {
    return await req.get(`api/services/app/Integrity/VerificationResult?form=${formId}&mstId=${mustId}`);
};
export const Verification= async (params)=>{
    return await req.post('api/services/app/RuleIntegrity/ExcuteRecalibration', params);
};
// export const Verification = async (params) => {
//     return await req.post('api/services/app/Integrity/Verification', params);
// };
/** 生成手工原始单 */
export const CopyToYSD = async (params) => {
    return await req.post('api/services/app/EaaMetadata/CopyToYSD', params);
};
/*复核审核列表 */
export const SearchCheckRecordList = async (params) => {
    return await req.post('api/services/app/FileReview/SearchCheckRecordList', params);
};
/*复核详情 */
export const SearchCheckRecordDetail = async (mstId) => {
    return await req.get(`api/services/app/FileReview/SearchCheckRecordDetail?mstId=${mstId}`);
};
/*匹配规则列表 */
export const QueryrMatchRuleMatchRe = async (params) => {
    return await req.post(
        "/documentservice/api/MatchRuleRelation/QueryrMatchRuleMatchRelationList",
        params
    );
};
/*匹配规则添加 */
export const InsertMatchRule = async (params) => {
    return await req.post(
        "/documentservice/api/MatchRuleRelation/InsertMatchRuleRelation",
        params
    );
};
/*匹配规则删除 */
export const DeleteMatchRuleRelation = async (params) => {
    return await req.post(
        "/documentservice/api/MatchRuleRelation/DeleteMatchRuleRelation",
        params
    );
};
/*批量更新修改*/
export const BatchModify = async (params) => {
    return await req.post(
        "/api/services/app/BatchOperation/BatchModify",
        params
    );
};
/*批量导入下载模板 */
export const getFormTempExcel = async (formId) => {
    return await req.get(`/api/services/app/BatchOperation/GetFormTempExcelRemark?formId=${formId}&isOrganization=${true}`);
}
/**
 * 报销单手动调用
 * @param {*} params
 * @returns
 */
export const ManuallyCallReimbursement = async (params) => {
    return await req.post('/archivesjobinterface/api/ManuallyCall/ManuallyCallReimbursement', params);
};
/**
 * 借款单手动调用
 * @param {*} params
 * @returns
 */
export const ManuallyCallLoan = async (params) => {
    return await req.post('/archivesjobinterface/api/ManuallyCall/ManuallyCallLoan', params);
};
/**
 * 还款单手动调用
 * @param {*} params
 * @returns
 */
export const ManuallyCallRepayment = async (params) => {
    return await req.post('/archivesjobinterface/api/ManuallyCall/ManuallyCallRepayment', params);
};
/**
 * 银行回单手动调用
 * @param {*} params
 * @returns
 */
export const ManuallyCallBankReceipt = async (params) => {
    return await req.post('/archivesjobinterface/api/ManuallyCall/ManuallyCallBankReceipt', params);
};
/**
 * 凭证关系手动调用
 * @param {*} params
 * @returns
 */
export const ManuallyCallVoucherRelation = async (params) => {
    return await req.post('/archivesjobinterface/api/ManuallyCall/ManuallyCallVoucherRelation', params);
};

/*影像查询-列表*/
export const QueryImageList = async (params) => {
    return await req.post(
        "/documentservice/api/Image/QueryImageList",
        params
    );
};
/*影像查询-导出数据*/
export const ExportImageData = async (params) => {
    // return await req.post(
    //     "/documentservice/api/Image/ExportImageData",
    //     params
    // );
    req.post('/documentservice/api/Image/ExportImageData', params, { responseType: 'blob' })
};
/*影像查询-下载影像*/
export const ExportImageFile = async (params) => {
    return await req.post(
        "/documentservice/api/Image/ExportImageFile",
        params
    );
};
/*影像查询-日志列表*/
export const ImageLogs = async (params) => {
    return await req.post(
        "/documentservice/api/ImageLogs/GetLogs",
        params
    );
};
/*影像查询-日志保存*/
export const SaveLogs = async (params) => {
    return await req.post(
        "/documentservice/api/ImageLogs/SaveLogs",
        params
    );
};
/*影像查询-分享 */
export const imageQueryPreview = async (url, mstid) => {
    return await req.get(`/documentservice/api/QRCode/GetQRCodeContent?url=${url}&mstid=${mstid}`);
};
/*影像查询-预览列表 */
export const GetPreviewTreeData = async (mstid, bill_no, mark) => {
    return await req.get(`/documentservice/api/Image/GetImageTreeData?input=${mstid}&mark=${mark}&bill_no=${bill_no}`);
};
/*影像查询-预览列表校验 */
export const CheckQRCode = async (input, bill_no) => {
    return await req.get(`/documentservice/api/QRCode/CheckQRCode?input=${input}&bill_no=${bill_no}`);
};
/*影像查询-业务类型列表*/
export const GetTypeTreeall = async (params) => {
    return await req.get(
        "/documentservice/api/Business/Management/GetTypeTree?isEnable=false"
    );
};
/**
 * 列表查询
 * @param {*} params
 * @returns
 */
export const HandoverQuery = async (params) => {
    return await req.post('/api/services/app/Handover/HandoverQuery', params);
}
/**
 * 接收
 * @param {*} params
 * @returns
 */
export const SubmitReceive = async (params) => {
    return await req.post('/api/services/app/Handover/Receive', params);
}

/**
 * 全文检索UI优化
 */
/**
 *
 * @returns 卷内
 */
export const getStoreList = async (params) => {
    return await req.post(`/documentfulltextsearch/api/ESRetrieval/GetNewArchivesStorePageList`, params);
};
/**
 *
 * @returns 电子
 */
export const getAnnexList = async (params) => {
    return await req.post(`/documentfulltextsearch/api/ESRetrieval/GetNewEdocumentPageList`, params);
};
/**
 * 全文检索UI优化
 */
/**
 *
 * @returns 卷内
 */
export const getSecondStoreList = async (params) => {
    return await req.post(`/documentfulltextsearch/api/ESRetrieval/GetSecondNewArchivesStorePageList`, params);
};
/**
 *
 * @returns 电子
 */
export const getSecondAnnexList = async (params) => {
    return await req.post(`/documentfulltextsearch/api/ESRetrieval/GetSecondNewEdocumentPageList`, params);
};
/**
 *
 * @returns 查询历史内容
 */
export const getHistoryList = async (params) => {
    return await req.post(`/documentfulltextsearch/api/ESRetrieval/GetSearchHistory`, params);
};
/**
 *
 * @returns 案卷信息
 */
export const getParentInfo = async (params) => {
    // return await req.post(`/api/ESRetrieval/GetSearchHistory`, params);
    return await req.get(`/api/services/app/FullText/GetParentInfoList`, params);
};
/**
 *
 * @returns 条目信息
 */
export const getMetadataInfo = async (params) => {
    // return await req.post(`/api/ESRetrieval/GetSearchHistory`, params);
    return await req.get(`/api/services/app/FullText/GetMetadataInfo`, params);
};

export const RenewArhives = async (params) => {
    return await req.post('/api/services/app/EaaApplicationProcess/RenewArchives', params);
}

export const RemoveMetadata = async (params) => {
    return await req.post('/api/services/app/EaaMetadata/RemoveMetadata', params);
}
/**
 * 获取票据类型列表
 * @param {*} data
 * @returns
 */
export const configIds = async () => {
    return await req.get(`/api/services/app/Xbrl/GetConfigIdList`);
};
/**
 * 上传xbrl
 * @param {*} data
 * @returns
 */
export const xbrlUpload = async (data) => {
    return await req.post(`/api/services/app/Xbrl/FetchXbrl`, data);
};
/**
 * 上传提取记录
 * @param {*} data
 * @returns
 */
export const xbrlUploadRecord = async (data) => {
    return await req.post(`/api/services/app/Xbrl/GetXbrlRecordList`, data);
};
/**
 * 查看详情
 * @param {记录唯一值 mstId} data
 * @returns
 */
export const xbrlResult = async (data) => {
    return await req.get(`/api/services/app/Xbrl/ViewXbrlFetchResult?mstId=${data}`);
}
/**
 * xbrl提取结果预览文件
 * @param {*} data
 * @returns
 */
export const ofdView = async (data) => {
    return await req.post(`/ofd/api/OfdService/get_ofd_invoice_html`, data);
}

export const ImagePreView = async (params) => {
    return await req.post(
        `api/services/app/RelationFormAndAnnex/GetImagePreViewList`,
        params
    );
};

export const GetFileList = async (params) => {
    return await req.post(
        `/api/services/app/RelationFormAndAnnex/GetFileList`,
        params
    );
};
export const GetInnerList = async (params) => {
    return await req.post(
        `/api/services/app/RelationFormAndAnnex/GetInnerList`,
        params
    );
};

/*银行余额调节表-关联匹配 */
export const AssociationMatching= async (params)=>{
    return await req.post('api/services/app/MetaDataFileRalation/BankAssociationMatching', params);
};

// 单点登录
export const ThirdLogin = async (data) => {
    return await req.get(`documentinterface/api/Third/GetThirdLoginName?code=${data}`);
}
// 单点登录地址
export const ThirdLoginPath = async () => {
    return await req.get(`documentinterface/api/Third/GetThirdLoginPath`);
}
